<template>
    <v-layout column>
        <v-subheader v-if="errors.length > 0">{{$t('application.import_errors')}}</v-subheader>
        <ul v-if="value.length > 0">
		    <li v-for="(error, index) in value" :key="index" v-text="error" />
		</ul>
    </v-layout>
</template>

<script>
export default {
    name: 'ErrorReport',
    props: {
        value: {
            required: true,
            type: Array
        }
    },
    computed: {
        errors: function () {
            return this.value
        }
    }
}
</script>